(<template>
  <ag-grid v-if="showGrid"
           :key="gridId"
           :column-defs="columnDefs"
           :grid-options="gridOptions"
           :fetch-data-func="fetchDataFunc"
           :success-export-request-func="successExportRequestFunc"
           :row-style-func="rowStyleFunc"
           :grid-data="gridData"
           @on-pagination-changed="onPaginationChanged"
           @row-clicked="onRowClicked"
           @interface="emitInterface" />
</template>)

<script>

  import Vue from 'vue';

  export default {
    props: {
      onPaginationChanged: {
        type: Function,
        default: () => {}
      },
      columnDefs: {
        type: Array,
        default: () => []
      },
      gridOptions: {
        type: Object,
        default: () => {}
      },
      fetchDataFunc: {
        type: Function,
        default: () => {}
      },
      successExportRequestFunc: {
        type: Function,
        default: () => {}
      },
      rowStyleFunc: {
        type: Function,
        default: () => {}
      },
      gridData: {
        type: Object,
        default: () => {}
      },
    },
    data() {
      return {
        gridId: '',
        showGrid: false
      };
    },
    watch: {
      $route() {
        setTimeout(() => {
          this.renderGridCore();
        }, 0);
      },
      mainProgressActive() {
        setTimeout(() => {
          this.renderGridCore();
        }, 0);
      }
    },
    methods: {
      renderGridCore() {
        this.$emit('startprogress');
        setTimeout(() => {
          Vue.component('ag-grid', () => import('@/components/grid/AgGridCore'));
          this.gridId = Math.random();
          this.showGrid = true;
          this.$emit('stopprogress');
        }, 0);
      },
      mainProgressActive() {
        return this.$store.state.GlobalStore.mainProgressActive;
      },
      emitInterface(args) {
        this.$emit('interface', args);
      },
      onRowClicked(event) {
        this.$emit('row-clicked', event);
      },
    },
    mounted() {
      this.renderGridCore();
    }
  };
</script>
